import GlobalError from '@/components/app/globalError/GlobalError';
import MainLayout from '@/components/app/layout/MainLayout';
import { getLocalesFromStaticProps } from '@/utils/locales';

const Page404 = () => (
  <MainLayout is404Page={true} showSidebar={false}>
    <GlobalError text="notFound" />
  </MainLayout>
);

export const getStaticProps = getLocalesFromStaticProps;

export default Page404;
